import { render, staticRenderFns } from "./SendersList.vue?vue&type=template&id=03b2244e&scoped=true&"
import script from "./SendersList.vue?vue&type=script&lang=js&"
export * from "./SendersList.vue?vue&type=script&lang=js&"
import style0 from "./SendersList.vue?vue&type=style&index=0&id=03b2244e&lang=scss&scoped=true&"
import style1 from "./SendersList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03b2244e",
  null
  
)

export default component.exports